.btnSubscribe {
  display: inline-block;
  background-color: #fff;
  padding: 10px;
  border-radius: 100px;
  position: relative;
  box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.05);
  ::after {
    content: '';
    background-image: url('./../../../assets/images/bgSubscribe.svg');
    background-repeat: no-repeat;
    background-size: 33px auto;
    display: block;
    width: 33px;
    height: 37px;
    position: absolute;
    top: -20px;
    right: -27px;
  }
}
