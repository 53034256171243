@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --mka-black-600: #282727;
  --mka-black: #606060;
  --mka-gray: #312f2f;
  --mka-gray-50: #ededed;
  --mka-gray-100: #d0d0d0;
  --mka-primary-100: #49ABAA;
  --mka-primary-400: #00B7B0;
  --mka-primary-700: #006159;
}

body,
html {
  height: 100vh;
}

body {
  margin: 0;
  font-family: 'UTM Avo', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: thin;
  color: var(--mka-black)!important;;
}

@media only screen and (min-width: 1280px) {
  .container{
    max-width: 1024px;
  }
}

