.header {
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  position: relative;
  text-align: center;
  :global(.header-text) {
    display: inline-block;
  }
}
