.wrapper {
  position: relative;
  background-image: url('./../../../assets/images/backgroundHeroSm.jpg');
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center bottom;
  min-height: 600px;
  display: flex;
  justify-content: center;

  @media only screen and (min-width: 768px) {
    background-image: url('./../../../assets/images/backgroundHeroLg.jpg');
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center;
    &::before {
      content: '';
      display: block;
      width: 50%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: #07bab3;
      z-index: -1;
    }
    &::after {
      content: '';
      display: block;
      width: 50%;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      background-color: #004941;
      z-index: -1;
    }
  }
}
